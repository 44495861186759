import React, { Component } from 'react'
import {
	Button,
	Header,
	Image
} from 'semantic-ui-react'
import * as styles from './slideshow.module.css'
import {Link} from 'gatsby'
import {
	CarouselProvider,
	Slide,
	Slider,
	ButtonBack,
	ButtonNext,
} from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import CustomDotGroup from './CustomDotGroup'

class SlideShow extends Component {
	state = {
		moving:false, //for disabling automatic animation while user is navigating slideshow so that they won't double jump
	}

	onLoad = (temp) =>{

	}

	disableAnimation = () => {
		this.setState({moving : !this.state.moving})
		setTimeout( ( ()=>{
			this.setState({moving : !this.state.moving})
		}), 2000)
	}


	render() {
		const {
			image,
			dynamicLoaded,
			url,
			itemVar,
			loadingVar,
			errorVar,
			error,
			retryFunc,
		}	= this.props
		const moving = this.state.moving
		var sliderimageratio = (480/1200) //ratio of banner images on 8/14/2020
		var hasArrow = true;
		var hasDot = true;
		if (this.props.hasOwnProperty("showArrow")) {
			hasArrow = this.props.showArrow;
		}
		if (this.props.hasOwnProperty("showDot")) {
			hasDot = this.props.showDot;
		}
		if (this.props.hasOwnProperty("imageratio")) {
			sliderimageratio=this.props.imageratio;
		}
		return (
			<React.Fragment>
				{ error===true ? (
					<div
						className={`${styles.text} ${styles.centerText}`}
					>
						<Header>
							Banner
						</Header>
						<Button
							icon="redo"
							size="big"
							className={styles.iconButton}
							onClick={()=>{
								retryFunc({url, itemVar, loadingVar, errorVar, type:"category"})
							}}
						/>
						<br/><br/>
						Try Again
					</div>
				):(
					<div>
						<CarouselProvider
							naturalSlideWidth={1}
							naturalSlideHeight={sliderimageratio}
							totalSlides={image.length}
							isPlaying={!moving}
							interval={5000}
							infinite={true}
						>
							<div >
								<Slider >
									{ image.map( ({name, url, mainImageHref}, index) => {
										let bannerProps = {}
										if(!dynamicLoaded) {
											bannerProps.as=Link
											if (url.indexOf("://")>0) {
												bannerProps.as = "a"
												bannerProps.href=url
												bannerProps.rel="noopener noreferrer"
												bannerProps.target="_blank"
											} else {
												bannerProps.to=`/${url}/`
											}
										}
										return (
											<Slide index={index} key={index}>
												{url ? (
													<Image
														fluid
														onLoad={this.onLoad}
														{...bannerProps}
														src={mainImageHref}
														alt={name}
													/>
												) : (
													<Image
														fluid
														src={mainImageHref}
														alt={name}
													/>
												)}
											</Slide>
										)
									})}
								</Slider>
								{(image.length > 1 && hasArrow) && (
									<React.Fragment>
										<ButtonBack
											className={`${styles.buttonBack} ${styles.button}`}
											onClick={this.disableAnimation}
										>
											{`<`}
										</ButtonBack>
										<ButtonNext
											className={`${styles.buttonNext} ${styles.button}`}
											onClick={this.disableAnimation}
										>
											{`>`}
										</ButtonNext>
									</React.Fragment>
								)}

							</div>
							{(image.length > 1 && hasDot) && (
								<CustomDotGroup
									quantity={image.length}
									onClick={this.disableAnimation}
								/>
							)}

						</CarouselProvider>
					</div>
				)}
			</React.Fragment>
		)
	}
}
export default SlideShow

