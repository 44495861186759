//header prop is required and needs to be unique or else there might be errors with multiple carousel
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import {
	Button,
	Card,
	Header,
	Image
} from 'semantic-ui-react'
import {Link} from 'gatsby'
import {
		CarouselProvider,
		Slide,
		Slider,
		ButtonBack,
		ButtonNext,
} from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import * as styles from './styles/carousel.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../../globalVar'




class PromoCarousel extends Component {
	state= {
		shown: this.props.initialShown,
		height: 1.4,
		timeOut1:undefined,
		timeOut2:undefined,
		windowWidth:undefined,
	}

	componentDidMount() {
		this._isMounted = true;
		this.handler();
		window.addEventListener('resize', this.resizeFunc)

		this.addDataLayer()
	}

	resizeFunc = () => {
		clearTimeout(this.state.timeOut1)
		const temp = setTimeout(this.handler, resizeTimer);
		if(this._isMounted) {
			this.setState({
				timeOut1:temp
			})
		}

	}


	addDataLayer = () => {
		const {
			dynamicLoaded,
			items,
			listName
		} = this.props
		if(!dynamicLoaded) { //dynamicLoaded=variable for checking if the items are still Loading, need to rename this or flip the values
			const dataLayerList = items.map(({name, sku, meta}, index)=>{
				return {
					'name': name,
					'id': sku,
					'price': parseFloat(meta.display_price.with_tax.amount),
					'list': listName,
					'position':index+1,
					'item_id': sku,
					'item_name': name,
					'currency': "PHP",
					'index': index+1,
					'item_list_id': listName,
					'item_list_name': listName,
					'quantity': 1
				}
			})
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'productList',
				'ecommerce': {
					'impressions': dataLayerList
				}
			});
		}
	}

	/* a function that uses both requestAnimationFrame and setTimeout to make sure the paint is done
	 * before calling the function to resize the cards and carousel Provider
	 * setTimeout to clear the callstack and requestAnimationFrame to make sure the dom is rendered
	 * before doing the resizing of cards/carousel.
	 */
	reqAnimationFrame = (time) => {
		clearTimeout(this.state.timeOut2)
		const callback = this.findCards
		const timeOut = setTimeout( () => {
			window.requestAnimationFrame(callback)
		},time)
		//clears the Timeout to make less repaints/re render for the screen
		if(this._isMounted) {
			this.setState({timeOut2:timeOut})
		}
	}

	componentDidUpdate(prevProps) {

		if(prevProps.items!==this.props.items) {
			/*so to resize the card sizes and carousel overall height since the items
			 *could have different heights for each card not to mention description/name
			 */
			this.addDataLayer()
			this.reqAnimationFrame(150);
		}
	}

	//resizes the cards to have uniform height and also changes the CarouselProvider height to fit the cards
	findCards = () => {
		if(this._isMounted) {
			const className = this.props.dynamicLoaded ? "test2":"test"
			const component = ReactDOM.findDOMNode(this)
			const images = component.getElementsByClassName(className)
			let finished = true
			for(let i=0; i<images.length;i++) {
				if(images[i].complete===false) {
					finished=false
					break;
				}
			}
			if(images.length===0) {
				this.reqAnimationFrame(150)
			}else if(finished===false) {
				this.reqAnimationFrame(150);
			}else {
				let cards
				/*checks just to make sure the component is mounted before doing component related stuff
				 *since this is called by a timeout an async function and component can unmount anytime
				 */
				if(this._isMounted) {
					cards = document.documentElement.getElementsByClassName(`cardBlogCarousel${this.props.header}`)
				}
				let height = 0
				let diff = 0
				if(this._isMounted) { // resets the styles set in place by another earlier instance of this function

					for(let i=0;i<cards.length;i++) {
						cards[i].style.height="initial";
					}
				}

				if(this._isMounted) {
					for( let i=0; i<cards.length;i++) {


						if( !this._isMounted ) {
							break;
						}
						if( height < cards[i].offsetHeight ) {
							height = cards[i].offsetHeight
						}
						/*+10 for just an offset since we have top:5px and an extra 5px is added here just to be sure borders don't get cut
						 *the 100/92 is for the offsetWidth is for the card className to get the full width of the Slide component
						 */
						if( diff < ( (cards[i].offsetHeight+10) / ( cards[i].offsetWidth* (100/92) ) ) ) {
							diff = (cards[i].offsetHeight+10)/ ( cards[i].offsetWidth* (100/92) ) // for the carousel Provider height
						}

					}
				}

				if(this._isMounted) {

					for( let i=0; i<cards.length;i++) {
						if( !this._isMounted ) {
							break;
						}
						cards[i].style.height = `${height}px`
					}
				}

				/*set the heights of the carousel with an offset to make sure carousel has a gap till the next component
				 *is also needed to make the component repaint showing the new height.
				 */
				if(this._isMounted) {
					this.setState({ height:diff})
				}
			}
		}
	}



	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener('resize', this.resizeFunc)
		clearTimeout(this.state.timeOut1)
		clearTimeout(this.state.timeOut2)

	}


	/* for changing how many are shown in the carousel when browser is resized could be replaced by the
	 * alternative suggested by the semantic ui react team on their upgrade guide from responsive component
	 * https://github.com/Semantic-Org/Semantic-UI-React/pull/4008
	 */
	handler = () => {
		//computer
		if(window.innerWidth > tabletMaxWidth ) {
			if(this._isMounted) {
				this.setState({
					shown : this.props.computerShown,
					windowWidth:window.innerWidth
				}, ()=>{
					this.reqAnimationFrame(0)
				})
			}

		} //tablet
		else if(window.innerWidth > mobileMaxWidth ){
			if(this._isMounted) {
				this.setState({
					shown:this.props.tabletShown,
					windowWidth:window.innerWidth
				}, ()=>{
					this.reqAnimationFrame(0)
				})
			}

		} //mobile
		else {
			if(this._isMounted) {
				this.setState({
					shown : this.props.mobileShown,
					windowWidth:window.innerWidth
				}, ()=>{
					this.reqAnimationFrame(0)
				})
			}

		}
	}

	render() {
		const {
			header,
			items,
			error,
			retryFunc,
			showHeader,
			cardContentClassName,
			textClassName
		} = this.props
		const {shown, height, windowWidth} = this.state
		let containerWidth = 0;
		let fontSize = 0;

		/*windowWidth if values are from https://semantic-ui.com/elements/container.html*/
		if(windowWidth > 1200 ) {
			fontSize= 11 *6.5/10
			containerWidth = 1127
		}else if( windowWidth <= 1200 && windowWidth >= 992 ) {
			fontSize= 10.5 *6.5/10
			containerWidth = 933
		}else if( windowWidth <= 991 && windowWidth >= 768 ) {
			fontSize= 9.5 *6.5/10
			containerWidth = 723
		}else if( windowWidth < 768 ) {
			fontSize= 9 *6.5/10
			containerWidth = windowWidth-28
		}

		let space = (containerWidth/shown ) -15 /*-15 for margin*/


		space = space / fontSize


		return (
			<div className={styles.container}>
				{showHeader && (
					<Header className={styles.header}>
						{header}
					</Header>
				)}

				{error===true ? (
					<div className={`${styles.text} ${styles.centerText}`}>
						<Button
							icon="redo"
							size="big"
							className={styles.iconButton}
							onClick={()=>{
								retryFunc()
							}}
						/>
						<br/><br/>
						Try Again
					</div>
				):(
					<CarouselProvider
						naturalSlideWidth={1}
						naturalSlideHeight={height}
						totalSlides={items.length}
						visibleSlides={shown}
						infinite={true}
					>
						<Slider className={styles.slider} >
							{ items.map( ({name, url, promoImageHref, summary, meta, sku}, index) => {
								let excerpt ="";
								if(summary!==undefined) {
									let strings = summary.split(" ");
									let length = 0; //length of current line
									let line = 1; //which line are we on
									for(let i=0;i<strings.length;i++) {
										if(line===3 && (length + strings[i].length +.5 > space-3) ) {
											const lastIndex = excerpt.lastIndexOf(" ");
											excerpt = excerpt.substr(0, lastIndex);
											excerpt = excerpt.concat("...");
											break;
										}else if( (length + strings[i].length +.5 > space) ) {
											line++;
											length = strings[i].length+.5
											excerpt = excerpt.concat(`${strings[i]} `);

										}else {
											length+=strings[i].length+.5;
											excerpt = excerpt.concat(`${strings[i]} `);
										}
									}
								}

								let cardProps = {}
								if(!this.props.dynamicLoaded) {
									cardProps.as = Link
									cardProps.to= `/${url}/`
								}
								return (
									<Slide
										index={index}
										key={url}
									>
										<Card
											{...cardProps}
											className={this.props.className ? `${this.props.className} cardBlogCarousel${header}`:`${styles.card} cardBlogCarousel${header}`}
											onClick={()=>{
												window.dataLayer = window.dataLayer || [];
												window.dataLayer.push({
													'event':'productClick',
													'ecommerce': {
														'click': {
															'actionField': {'list':this.props.listName},
															'products': [{
																'name':name,
																'id':sku,
																'price':parseFloat(meta.display_price.with_tax.amount),
																'position':index+1,
																'item_id': sku,
																'item_name': name,
																'currency': "PHP",
																'index': index+1,
																'item_list_id': this.props.listName,
																'item_list_name': this.props.listName,
																'quantity': 1
															}]
														}
													}
												});
											}}

										>
											<Image
												src={promoImageHref}
												className={this.props.dynamicLoaded ? "test2":"test"}
											/>
											<Card.Content className={cardContentClassName ? cardContentClassName:styles.blogContent}>
												<Card.Header className={textClassName? `${styles.blogHeader} ${textClassName}`:`${styles.blogHeader} ${styles.blogText}`}>
                                                    {name}
                                                </Card.Header>
                                                <div className={textClassName? `${styles.blogSubHeader} ${textClassName}`:`${styles.blogSubHeader} ${styles.blogText}`}>
													{ meta.display_price.with_tax.formatted}
												</div>
												{ excerpt!=="" && (
													<Card.Description className={textClassName ? textClassName:styles.blogText}>
														{excerpt}
													</Card.Description>
												)}
											</Card.Content>



										</Card>
									</Slide>
								)
							})}
						</Slider>
						{shown < items.length && (
							<React.Fragment>
								<ButtonBack
									className={`${styles.buttonBack} ${styles.navButton}`}
								>
									{`<`}
								</ButtonBack>
								<ButtonNext
									className={`${styles.buttonNext} ${styles.navButton}`}
								>
									{`>`}
								</ButtonNext>
							</React.Fragment>
						)}
					</CarouselProvider>
				)}
			</div>
		)
	}
}
export default PromoCarousel

PromoCarousel.defaultProps = {
	showHeader:true, //to show the Header component, can't just pass no Header to this component or else ALL(not just the ones in carousel) the semantic ui react card is gotten by the getElementsByClassName
	initialShown:4,
	computerShown:4,
	tabletShown:3,
	mobileShown:2
}