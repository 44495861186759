import React from 'react'
import {navigate , Link, useStaticQuery, graphql} from 'gatsby'
import { Dropdown, Menu } from 'semantic-ui-react'

import * as styles from './styles/menu.module.css'


const MenuBanner = () => {
	const MenuTemp = useStaticQuery(graphql`
		query MenuBannerQuery {
			example {
				result {
					name
					url
					children {
						squareImageHref
						name
						url
					}
				}
			}
		}
	`)

	const MakeMenuContent = (item, index, obj) => {
		if(item.children) {
			return (
				<React.Fragment key={item.name}>
					{item.children.length > 0 ? (
						<Dropdown
							onClick={()=>{
								navigate(`/${item.url}/`)
							}}
							item
							simple
							className={styles.bannerMenu}
						>
							<React.Fragment>
								<span>
									<Link to={`/${item.url}/`} className={styles.link}>
										{item.name}
									</Link>
								</span>
								<Dropdown.Menu
									onClick={(event)=>{
										event.stopPropagation()
									}}
									className={styles.bannerSubMenu}
								>
									{item.children.map(MakeMenuContent)}
								</Dropdown.Menu>
							</React.Fragment>
						</Dropdown>
					):(
						<Dropdown.Item
							as={Link}
							to={`/${item.url}/`}
							key={item.name}
							className={styles.bannerMenu}
						>
							{item.name}
						</Dropdown.Item>
					)}
				</React.Fragment>

			)
		}
		else {
			return (
				<Dropdown.Item
					as={Link}
					to={`/${item.url}/`}
					key={item.name}
					className={styles.bannerMenu}
				>
					{item.name}
				</Dropdown.Item>
			)
		}

	}
	const menu= MenuTemp.example.result
	const result = menu.map(MakeMenuContent)
	return (
		<Menu vertical  className={`${styles.mainMenu} ${styles.mainMenuBanner}`}>
			<Dropdown.Item
				as={Link}
				to={`/franchise/`}
				className={styles.bannerMenu}
			>
				Franchise
			</Dropdown.Item>
			{result}
			<Dropdown.Item
				as={Link}
				to={`/business/`}
				className={styles.bannerMenu}
			>
				Negosyo
			</Dropdown.Item>
			<Dropdown.Item
				as={Link}
				to={`/seminar/`}
				className={styles.bannerMenu}
			>
				Seminars
			</Dropdown.Item>
			<Dropdown.Item
				as={Link}
				to={`/recipes/`}
				className={styles.bannerMenu}
			>
				Recipes and Videos
			</Dropdown.Item>
			<Dropdown.Item
				as={Link}
				to={`/stories/`}
				className={styles.bannerMenu}
			>
				Stories and Tips
			</Dropdown.Item>
			{false &&
				<Dropdown.Item
					as={Link}
					to={`/howto/`}
					className={styles.bannerMenu}
				>
					How-To Guides
				</Dropdown.Item>
			}
		</Menu>

	)

}


export default MenuBanner