import PropTypes from "prop-types";
import { Dot } from "pure-react-carousel";
import React from "react";
import { Button, Container } from "semantic-ui-react";
import * as styles from './slideshow.module.css'

const CustomDotGroup = ({ quantity, size, onClick }) => {
	return (
		<Container textAlign="center">
			<Button.Group
				size={size}
				className={styles.dotGroup}
			>
				{[...Array(quantity).keys()].map(slide => (
					<Button
						as={Dot}
						key={slide}
						icon="circle"
						slide={slide}
						onClick={onClick}
						className={styles.dot}
					/>
				))}
			</Button.Group>
		</Container>
	)
}

CustomDotGroup.defaultProps = {
	size: "mini"
};

CustomDotGroup.propTypes = {
	quantity: PropTypes.number.isRequired,
	size: PropTypes.string
};

export default CustomDotGroup;
