import React, { Component } from 'react'
import {graphql} from 'gatsby'
import {
	Container,
	Grid,
} from 'semantic-ui-react'

import Seo from '../components/SEO'
import Layout from '../components/Layout'
import * as styles from './styles/pages.module.css'
import SlideShow from '../components/SlideShow'
import Carousel from '../components/Carousel'
import BlogCarousel from '../components/Carousel/blogCarousel'
import PromoCarousel from '../components/Carousel/promoCarousel'
import MenuBanner from '../components/Menu/menuBanner'


const Negosyo = require('../../lib/negosyo')

export default class StoreIndex extends Component {
	state = {
		loading: false,
		data: undefined,
		banner: this.props.data.negosyoNow.listLoadingBanner,
		featuredBusinessCarousel:this.props.data.negosyoNow.listLoadingCategory,
		featuredRecipeCarousel:this.props.data.negosyoNow.listLoadingBlog,
		featuredCarousel:this.props.data.negosyoNow.listLoadingItem,
		popularCarousel:this.props.data.negosyoNow.listLoadingCategory,
		newItemsCarousel:this.props.data.negosyoNow.listLoadingItem,
		promoItemsCarousel:[],
		shown:undefined,
		startedBanner:true, //true if loading simultaneously
		loadingBanner:true,
		errorBanner:false,
		startedFeaturedBusiness:true, //state to know that the fetch for featured business has started //true if loading simultaneously
		loadingFeaturedBusiness:true,
		errorFeaturedBusiness:false,
		startedFeaturedRecipe:true, //state to know that the fetch for featured business has started //true if loading simultaneously
		loadingFeaturedRecipe:true,
		errorFeaturedRecipe:false,
		startedFeatured:true, //state to know that the fetch for featured has started //true if loading simultaneously
		loadingFeatured:true,
		errorFeatured:false,
		startedPopular:true, //state to know that the fetch for popular has started //true if loading simultaneously
		loadingPopular:true,
		errorPopular:false,
		startedNew:true, //state to know that the fetch for new has started //true if loading simultaneously
		loadingNew:true,
		errorNew:false,

		startedPromoItems: true,
		loadingPromoItems:true,
		errorPromoItems:false,
	}

	componentDidMount()
	{
		this.fetchData()
	}

	fetchData = () => {
		/*
		const {
			handleContentLoad
		} = this

		Negosyo.getCategorySearch("banner")
		.then((response)=> {
			this.setState({
				banner:response,
				loadingBanner:false
			}, ()=> {
				handleContentLoad({
					type:"category",
					url:"featuredbusiness",
					errorVar:"errorFeaturedBusiness",
					itemVar:"featuredBusinessCarousel",
					loadingVar:"loadingFeaturedBusiness",
					startedVar:"startedFeaturedBusiness"
				}).then(()=>{
					handleContentLoad({
						type:"product",
						url:"featureditems",
						errorVar:"errorFeatured",
						itemVar:"featuredCarousel",
						loadingVar:"loadingFeatured",
						startedVar:"startedFeatured"
					}).then(()=>{
						handleContentLoad({
							type:"category",
							url:"featuredrecipes",
							errorVar:"errorFeaturedRecipe",
							itemVar:"featuredRecipeCarousel",
							loadingVar:"loadingFeaturedRecipe",
							startedVar:"startedFeaturedRecipe"
						}).then(()=>{
							handleContentLoad({
								type:"category",
								url:"popularcategory",
								errorVar:"errorPopular",
								itemVar:"popularCarousel",
								loadingVar:"loadingPopular",
								startedVar:"startedPopular"
							}).then(()=>{
								handleContentLoad({
									type:"product",
									url:"newitems",
									errorVar:"errorNew",
									itemVar:"newItemsCarousel",
									loadingVar:"loadingNew",
									startedVar:"startedNew"
								})
							})
						})
					})
				})

			})
		}).catch((err)=>{
			this.setState({
				loadingBanner:false,
				errorBanner:true
			}, ()=>{
				handleContentLoad({
					type:"category",
					url:"featuredbusiness",
					errorVar:"errorFeaturedBusiness",
					itemVar:"featuredBusinessCarousel",
					loadingVar:"loadingFeaturedBusiness",
					startedVar:"startedFeaturedBusiness"
				}).then(()=>{
					handleContentLoad({
						type:"product",
						url:"promo",
						errorVar:"errorPromoItems",
						itemVar:"promoItemsCarousel",
						loadingVar:"loadingPromoItems",
						startedVar:"startedPromoItems"
					}).then(()=>{
						handleContentLoad({
							type:"product",
							url:"featureditems",
							errorVar:"errorFeatured",
							itemVar:"featuredCarousel",
							loadingVar:"loadingFeatured",
							startedVar:"startedFeatured"
						}).then(()=>{
							handleContentLoad({
								type:"category",
								url:"featuredrecipes",
								errorVar:"errorFeaturedRecipe",
								itemVar:"featuredRecipeCarousel",
								loadingVar:"loadingFeaturedRecipe",
								startedVar:"startedFeaturedRecipe"
							}).then(()=>{
								handleContentLoad({
									type:"category",
									url:"popularcategory",
									errorVar:"errorPopular",
									itemVar:"popularCarousel",
									loadingVar:"loadingPopular",
									startedVar:"startedPopular"
								}).then(()=>{
									handleContentLoad({
										type:"product",
										url:"newitems",
										errorVar:"errorNew",
										itemVar:"newItemsCarousel",
										loadingVar:"loadingNew",
										startedVar:"startedNew"
									})
								})
							})
						})
					})
				})
			})
		})
		*/

		Negosyo.getCategorySearch("banner")
		.then((response)=> {
			this.setState({
				banner:response,
				loadingBanner:false
			})
		}).catch((err)=>{
			this.setState({
				loadingBanner:false,
				errorBanner:true
			})
		})
		Negosyo.getCategorySearch("featuredbusiness")
		.then((response)=> {
			this.setState({featuredBusinessCarousel:response, loadingFeaturedBusiness:false})
		}).catch((err)=>{
			this.setState({loadingFeaturedBusiness:false, errorFeaturedBusiness:true})
		})

		Negosyo.getProductSearch("promo")
		.then((response)=> {
			this.setState({promoItemsCarousel:response, loadingPromoItems:false})
		}).catch((err)=>{
			this.setState({loadingPromoItems:false, errorPromoItems:true})
		})
		Negosyo.getCategorySearch("featuredrecipes")
		.then((response)=> {
			this.setState({featuredRecipeCarousel:response, loadingFeaturedRecipe:false})
		}).catch((err)=>{
			this.setState({loadingFeaturedRecipe:false, errorFeaturedRecipe:true})
		})

		Negosyo.getCategorySearch("featureditems")
		.then((response)=> {
			this.setState({featuredCarousel:response, loadingFeatured:false})
		}).catch((err)=>{
			this.setState({loadingFeatured:false, errorFeatured:true})
		})
		Negosyo.getCategorySearch("popularcategory")
		.then((response)=> {
			this.setState({popularCarousel:response, loadingPopular:false})
		}).catch((err)=>{
			this.setState({loadingPopular:false, errorPopular:true})
		})
		Negosyo.getCategorySearch("newitems")
		.then((response)=> {
			this.setState({newItemsCarousel:response, loadingNew:false})
		}).catch((err)=>{
			this.setState({loadingNew:false, errorNew:true})
		})

	}

	//a function that returns a promise to load a certain section of the homepage
	handleContentLoad = ({type, url, errorVar, itemVar, loadingVar, startedVar}) => {
		this.setState({
			[startedVar]:true
		})
		return new Promise(resolve => {
			if(type==="category") {
				Negosyo.getCategorySearch(url)
				.then((response)=> {
					this.setState({
						[itemVar]:response,
						[loadingVar]:false
					})
					resolve(true)
				}).catch((err)=>{
					this.setState({
						[loadingVar]:false,
						[errorVar]:true
					})
					resolve(true)
				})
			}else if(type==="product") {
				Negosyo.getProductSearch(url)
				.then((response)=> {
//					if (url === "promo")
						console.log(response);
					this.setState({
						[itemVar]:response,
						[loadingVar]:false
					})
					resolve(true)
				}).catch((err)=>{
					this.setState({
						[loadingVar]:false,
						[errorVar]:true
					})
					resolve(true)
				})
			}
		})
	}

	handleContentReload = ({type, url, errorVar, itemVar, loadingVar}) => {
		this.setState({[loadingVar]:true, [errorVar]:false}, ()=>{
			if(type==="category" || type==="business") {
				Negosyo.getCategorySearch(url)
				.then((response)=> {
					this.setState({[itemVar]:response, [loadingVar]:false})
				}).catch((err)=>{
					this.setState({[loadingVar]:false, [errorVar]:true})
				})
			}else {
				Negosyo.getProductSearch(url)
				.then((response)=> {
					this.setState({[itemVar]:response, [loadingVar]:false})
				}).catch((err)=>{
					this.setState({[loadingVar]:false, [errorVar]:true})
				})
			}
		})

	}

	render() {
		const siteTitle = this.props.data.site.siteMetadata.title;
		const location = this.props.location

		const {
			banner,
			featuredBusinessCarousel,
			featuredRecipeCarousel,
			featuredCarousel,
			popularCarousel,
			newItemsCarousel,
			startedBanner,
			loadingBanner,
			errorBanner,
			startedFeaturedBusiness,
			loadingFeaturedBusiness,
			errorFeaturedBusiness,
			startedFeaturedRecipe,
			loadingFeaturedRecipe,
			errorFeaturedRecipe,
			startedFeatured,
			loadingFeatured,
			errorFeatured,
			startedNew,
			loadingNew,
			errorNew,
			startedPopular,
			loadingPopular,
			errorPopular,
		} =this.state
		const {
			handleContentReload
		} = this

		var structuredData = {
			"@context": "https://schema.org",
			"@type": "Organization",
			"sameAs": [
				"https://www.instagram.com/negosyonow/",
				"https://www.youtube.com/c/negosyonow/",
				"https://twitter.com/negosyonowcom/",
				"https://www.facebook.com/negosyonow/"
			],
			"url": "https://negosyonow.com/",
			"description": "Negosyo Now is an online store that focuses on the needs of the Negosyantes, new and old.",
			"mainEntityOfPage": "NegosyoNow",
			"brand": "https://negosyonow/",
			"address": {
				"@type": "PostalAddress",
				"addressRegion": "Metro Manila",
				"postalCode": "1403",
				"addressCountry": "PH",
				"addressLocality": "Caloocan City",
				"streetAddress": "704 Rizal Ave. ext."
			},
			"email": "inquiries@negosyonow.com",
			"telephone": "+639176218246",
			"name": "NegosyoNow",
			"image": "https://cdn.ngnw.ph/images/share/wide.jpg",
			"logo": "https://cdn.ngnw.ph/logo.png",
			"@id": "https://negosyonow.com/"
		}

		return (
			<Layout location={location}>
				<Seo
					title={siteTitle}
					canonicalpath=""
					structuredData={structuredData}
				/>

				<Container >
					{startedBanner && (
						<React.Fragment>
							<div className={styles.desktopOnly}>
								<Grid>
									<Grid.Row stretched >
										<Grid.Column width={4} >
											<MenuBanner/>
										</Grid.Column>
										<Grid.Column width={12} >
											<SlideShow
												image={banner}
												dynamicLoaded={loadingBanner}
												url={"banner"}
												itemVar={"banner"}
												loadingVar={"loadingBanner"}
												errorVar={"errorBanner"}
												error={errorBanner}
												retryFunc={handleContentReload}
											/>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</div>
							<div className={styles.mobileOnly}>
								<Grid>
									<Grid.Row >

										<Grid.Column width={16} >
											<SlideShow
												image={banner}
												dynamicLoaded={loadingBanner}
												url={"banner"}
												itemVar={"banner"}
												loadingVar={"loadingBanner"}
												errorVar={"errorBanner"}
												error={errorBanner}
												retryFunc={handleContentReload}
											/>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</div>
						</React.Fragment>
					)}
					{ startedFeaturedBusiness && (
						<Carousel
							items={featuredBusinessCarousel}
							shown={5}
							header={"Featured Business"}
							type={"business"}
							categoryClassName={styles.featuredBusiness}
							dynamicLoaded={loadingFeaturedBusiness}
							url={"featuredbusiness"}
							itemVar={"featuredBusinessCarousel"}
							loadingVar={"loadingFeaturedBusiness"}
							errorVar={"errorFeaturedBusiness"}
							error={errorFeaturedBusiness}
							retryFunc={handleContentReload}
						/>
					)}
					{this.state.promoItemsCarousel.length > 0 && (
						<>
							<PromoCarousel
								items={this.state.promoItemsCarousel}
								listName={`Home-Promo Items`}
								header={"Promos and Limited Offerings"}
								dynamicLoaded={this.state.loadingPromoItems}
								error={this.state.errorPromoItems}
								retryFunc={()=>{
									handleContentReload({
										url:"promo",
										itemVar:"promoItemsCarousel",
										loadingVar:"loadingPromoItems",
										errorVar:"errorPromoItems",
										type:"product"
									})
								}}
							/>

						</>
					)}
					{startedFeatured && (
						<Carousel
							items={featuredCarousel}
							shown={5}
							header={"Featured Items"}
							type={"product"}
							dynamicLoaded={loadingFeatured}
							itemVar={"featuredCarousel"}
							loadingVar={"loadingFeatured"}
							errorVar={"errorFeatured"}
							url={"featureditems"}
							error={errorFeatured}
							retryFunc={handleContentReload}
							listName={`Home-Featured Items`}
						/>
					)}
					{ startedFeaturedRecipe && (
						<BlogCarousel
							items={featuredRecipeCarousel}
							header={"Featured Recipes"}
							dynamicLoaded={loadingFeaturedRecipe}
							error={errorFeaturedRecipe}
							retryFunc={()=>{
								handleContentReload({
									url:"featuredrecipes",
									itemVar:"featuredRecipeCarousel",
									loadingVar:"loadingFeaturedRecipe",
									errorVar:"errorFeaturedRecipe",
									type:"category"
								})
							}}
						/>
					)}
					{startedPopular && (
						<Carousel
							items={popularCarousel}
							shown={5}
							header={"Popular Categories"}
							type={"category"}
							categoryClassName={styles.popularCategory}
							dynamicLoaded={loadingPopular}
							itemVar={"popularCarousel"}
							loadingVar={"loadingPopular"}
							errorVar={"errorPopular"}
							url={"popularcategory"}
							error={errorPopular}
							retryFunc={handleContentReload}
						/>
					)}
					{startedNew && (
						<Carousel
							items={newItemsCarousel}
							shown={5}
							header={"New Items"}
							type={"product"}
							dynamicLoaded={loadingNew}
							itemVar={"newItemsCarousel"}
							loadingVar={"loadingNew"}
							errorVar={"errorNew"}
							url={"newitems"}
							error={errorNew}
							retryFunc={handleContentReload}
							listName={`Home-New Items`}
						/>
					)}
				</Container>
			</Layout>
		)
	}
}


export const data = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		negosyoNow {
			listLoadingBanner {
				name
				mainImageHref
				url
			}
			listLoadingBlog {
				name
				squareImageHref
				url
			}
			listLoadingCategory {
				name
				squareImageHref
				url
			}
			listLoadingItem {
				available
				id
				mainImageHref
				name
				url
				meta {
					display_price {
						with_tax {
							currency
							formatted
						}
					}
				}
			}
		}
	}
`
